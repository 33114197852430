<template>
  <div>
    <h2>other stuff</h2>
    <p>hi! i'm matt and i do other stuff, too.</p>
    <ul>
      <li><a href="https://github.com/mattpolicastro" target="_blank" rel="noopener">github</a></li>
      <li><a href="https://twitter.com/mattpolicastro" target="_blank" rel="noopener">twitter</a></li>
    </ul>
  </div>
</template>

<style scoped>
h3 {
  margin: 40px 0 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
